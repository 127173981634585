// Thai
export default{
    form: {
        startSurveyButton: "เริ่มต้น",
        nextButton: "ถัดไป",
        submitAnswerButton: "ส่งคำตอบ",
        continueButton: "ดำเนินการต่อ",
        finishButton: "เสร็จสิ้น",
        headerPercentageCompletion: 'เสร็จสมบูรณ์',
        headerQuestionsCompleted: 'คำถาม',
        headerDefaultErrorMessage: 'อุ๊ย เกิดข้อผิดพลาดบางอย่าง',
        brandingPoweredBy: 'สนับสนุนโดย',
        brandingPromoWhySurvey: 'ทำไมต้องทำแบบสำรวจเมื่อคุณสามารถ <b>{product}</b>?',
        brandingPromoGetStarted: 'เริ่มต้นวันนี้!',
        submissionPending: "กำลังส่งฟอร์มเสียงของคุณ กรุณาอย่าปิดหน้าต่างเบราว์เซอร์ของคุณ",
        submissionCanCloseWindow: "ฟอร์มเสียงของคุณถูกส่งเรียบร้อยแล้ว คุณสามารถปิดหน้าต่างได้ตอนนี้ 👍",
        mediaAttachmentOverlayCloseButton: "ปิด",
        expiredFormHeader: "ขอบคุณที่แวะมา!",
        expiredFormMessage: "ดูเหมือนว่าแบบสำรวจนี้หมดอายุแล้ว กรุณาติดต่อผู้สร้างฟอร์มเสียงเพื่อแจ้งให้ทราบ",
        notPublishedFormHeader: "ฟอร์มเสียงนี้ยังไม่ได้เผยแพร่!",
        notPublishedFormMessage: "ดูเหมือนว่าแบบสำรวจนี้ยังไม่ได้เผยแพร่ กรุณาติดต่อผู้สร้างฟอร์มเสียงเพื่อแจ้งให้ทราบ",
        notPublishedFormRetryButton: "ลองอีกครั้ง",
        errorFormHeader: "โอ๊ะ!",
        errorFormMessage: "บางอย่างอาจจะผิดพลาด กรุณาลองใหม่อีกครั้งในภายหลัง",
        errorFormRetryButton: "ลองอีกครั้ง",
        emptyFormHeader: "ขอบคุณที่แวะมา!",
        emptyFormMessage: "โอ๊ะ! ดูเหมือนว่าฟอร์มนี้ไม่มีคำถามหรือฟิลด์ให้กรอกในขณะนี้ เราขออภัยในความไม่สะดวกนี้ หากต้องการแก้ไข โปรดติดต่อผู้สร้างฟอร์มเพื่อแจ้งปัญหา ขอบคุณสำหรับความเข้าใจและความร่วมมือของคุณ!",

        restoreFormHeader: "ดูเหมือนว่าคุณมีฟอร์มที่ยังไม่ได้กรอก!",
        restoreFormMessage: "คุณต้องการกลับไปยังจุดที่ค้างไว้หรือไม่?",
        restoreFormButton: "ดำเนินการต่อในฟอร์ม",
        restoreFormButtonNew: "เริ่มใหม่",

        audioPermissionHeader: "คุณต้องการตอบด้วยเสียงหรือไม่?",
        audioPermissionMessage: "การพูดจะทำให้การตอบกลับรวดเร็วและง่ายขึ้น",
        audioPermissionAcceptButton: "ใช่ ฉันต้องการ",
        audioPermissionDenyButton: "ไม่ ขอบคุณ",
        audioPermissionErrorHeader: "ไมโครโฟนไม่พร้อมใช้งาน",
        audioPermissionErrorMessage: "กรุณาตรวจสอบสิทธิ์การเข้าถึงไมโครโฟนในเบราว์เซอร์ของคุณ",
        audioPermissionErrorButton: "ตกลง",
        audioRecorderFailedUpload: "ไม่สามารถอัปโหลดการบันทึกได้",
        silenceDetectorError: "ไม่ได้ยินเสียงของคุณ กรุณาตรวจสอบการตั้งค่าไมโครโฟนของคุณ คำแนะนำ: อาจเป็นไมโครโฟนภายนอก",
        audioRecorderButtonRecord: "กดบันทึก",
        audioRecorderButtonRecordMore: "บันทึกเพิ่มเติม",
        voiceResponseSelectionMessage: "เลือกวิธีการตอบ...",
        voiceResponseSelectionOrDivider: "หรือ",
        voiceResponseTextInputPlaceholder: "พิมพ์คำตอบ",
        voiceResponseTextInputCharacterCounterMinMessage: "กรุณากรอกอย่างน้อย {min} ตัวอักษร",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} ตัวอักษร (ขั้นต่ำ {min} ตัวอักษร)",
        voiceResponseTextInputCharacterValidationMessage: "{count} ตัวอักษร (ขั้นต่ำ {min} ตัวอักษร)",

        // checkbox
        checkboxValidationTooFew: "คุณต้องเลือกอย่างน้อย {min} ตัวเลือก",
        checkboxValidationTooMany: "คุณสามารถเลือกได้ไม่เกิน {max} ตัวเลือก",
        checkboxNoneOfTheAboveOption: "ไม่มีข้อใดข้างต้น",
        checkboxInvalidAnswer: "คำตอบไม่ถูกต้อง",

        // datepicker
        datePickerPlaceholder: "เลือกวันที่",

        // dropdown
        // email
        emailLabel: "อีเมล",
        emailInvalid:"อีเมลไม่ถูกต้อง",
        // file-upload
        fileUploadPluginNameCamera: "กล้อง",
        fileUploadPluginNameCameraVideo: "บันทึกวิดีโอ",
        fileUploadPluginScreenCast: "บันทึกหน้าจอ",
        fileUploadVideoImportFilesDefault: 'บันทึกหรืออัปโหลดวิดีโอผ่าน:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'เลือกตัวเลือกบันทึกวิดีโอ:',
        fileUploadVideoImportFilesNoCamera: 'กดปุ่มเพื่อบันทึกวิดีโอหน้าจอ',
        fileUploadVideoImportFilesNoScreenCast: 'กดปุ่มเพื่อบันทึกวิดีโอ',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'ไม่มีตัวเลือกให้บันทึกวิดีโอ',

        // matrix
        matrixValidationMessage: "คำตอบถูกต้อง",
        matrixRow: "แถว",
        matrixColumn: "คอลัมน์",
        matrixRowRequired: "แถว {rowTitle} จำเป็น",
        matrixRadioGroup: "กลุ่มวิทยุ",
        matrixCheckboxGroup: "กลุ่มกล่องกาเครื่องหมาย",
        matrixGroupRequired: "สำหรับแถว {rowTitle}. {type} {groupTitle} จำเป็น",
        matrixColumnRequired: "สำหรับแถว {rowTitle}. คอลัมน์ {columnTitle} จำเป็น",
        matrixColumnInvalid: "สำหรับแถว {rowTitle}. คอลัมน์ {columnTitle} ไม่ถูกต้อง",
        matrixRequired: "จำเป็น",
        matrixNumericMustBeNumber: "ต้องเป็นตัวเลข",
        matrixNumericMustBeGreaterThenMin: "ต้องมากกว่า {min}",
        matrixNumericMustBeLessThenMax: "ต้องน้อยกว่า {max}",
        matrixNumericMustBeInteger: "ต้องเป็นจำนวนเต็ม",
        matrixNumericInvalidNumber: "หมายเลขไม่ถูกต้อง",

        // name
        nameLabel: "ชื่อ",
        nameInvalid: "โปรดระบุชื่อและนามสกุล",

        // nps
        npsNotLikely: "ไม่น่าเป็นไปได้เลย",
        npsExtremelyLikely: "มีความเป็นไปได้สูง",

        // numeric input
        numericInputRequired: "จำเป็น",
        numericInputMustBeNumber: "ต้องเป็นตัวเลข",
        numericInputMustBeGreaterThenMin: "ต้องมากกว่า {min}",
        numericInputMustBeLessThenMax: "ต้องน้อยกว่า {max}",
        numericInputMustBeInteger: "ต้องเป็นจำนวนเต็ม",
        numericInputInvalidNumber: "หมายเลขไม่ถูกต้อง",
        numericInputPlaceholder: "กรอกตัวเลข",

        // phone
        phoneInvalid: "หมายเลขโทรศัพท์ไม่ถูกต้อง",
        phoneCountrySelectorLabel: 'รหัสประเทศ',
        phoneCountrySelectorError: 'เลือกประเทศ',
        phoneNumberLabel: 'หมายเลขโทรศัพท์',
        phoneExample: 'ตัวอย่าง:',

        // boolean
        booleanYesLabel: "ใช่",
        booleanNoLabel: "ไม่",

        //questionStep
        questionStepAudioQuestionLabel: "คำถามเสียง",

        // errors
        invalidPhoneNumber: "{phone} เป็นหมายเลขโทรศัพท์ที่ไม่ถูกต้อง",
        invalidEmail: "{email} เป็นอีเมลที่ไม่ถูกต้อง",
        questionIsRequired: "คำถามนี้จำเป็นต้องตอบ",
        answerIsNotValid: "คำตอบไม่ถูกต้อง",
        unfinishedProbingDialogError: "กรุณาทำบทสนทนาให้เสร็จ",
        cannotResumePartialResponse: "ไม่สามารถดำเนินการตอบกลับบางส่วนต่อได้",
        failedToSubmitForm: "การส่งฟอร์มล้มเหลว กรุณาตรวจสอบการเชื่อมต่ออินเทอร์เน็ตของคุณและลองใหม่",

        //language picker
        searchLanguage: "ค้นหาภาษา",
    }
}
